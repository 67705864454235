<template>
    <!-- TODO - Nate needs to add text-transform property to capitalize this. -->
    <div className="form-client">
        <h2 class="margin-0 pointer" @click="gotoclient" v-if='showClientName'>{{ client.last_name }}, {{ client.first_name }}</h2>
        <div class="dark-text" @click="gotoclient" v-if='showDob'>DOB {{ formattedDob }}</div>
        <div class="pointer blue weight-600 hover-shadow inline-block" @click="gotoclientdocs" v-if='showId'>ID# {{ client.id }}</div>
        <div v-if='showInsurances'>
            <div class="dark-text" @click="gotoclient" v-for="ins of insurance.insurances" :key="ins.id">
                <span v-if="ins.member_id !== ''"> Insurance# {{ ins.member_id }}</span>
            </div>
        </div>
        <button class="top-5" type="button" v-if="siggys && parseInt(form_data_id) > 0" @click.prevent="portal2sign" >Send to Portal for Member to Sign</button>
        <button class="top-5" type="button" v-if="pageMeta.shareable_to_client && parseInt(form_data_id) == 0" @click.prevent="create_portal2sign()" >Create Blank and Share to Portal for Member to Sign</button>
    </div>
</template>

<script>
    import { clients } from '@/util/apiRequests';

    import dayjs from 'dayjs';

    export default {
        name: 'FormClient',
        props: {
            client_id: {
                type: Number,
                default: 0
            },
            form_data_id: {
                type: [String, Number],
                required: false,
                default: 0
            },
            siggys: {
                type: [String, Number],
                required: false,
                default: 0
            },
            showDob: {
                type: Boolean,
                required: false,
                default: true
            },
            showId: {
                type: Boolean,
                required: false,
                default: true
            },
            showInsurances: {
                type: Boolean,
                required: false,
                default: true
            },
            showClientName: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data() {
            return {
                loading: 1,
                client: {
                    // id : 0,
                    // firstName : "",
                    // lastName : "",
                },
                insurance: '',
                portalling : 0,
            };
        },
        computed: {
            formattedDob() {
                return this.client && this.client.dob ? dayjs(this.client.dob.split('T')[0]).format('MM/DD/YYYY') : '';
            }
        },
        methods: {
            async getClient() {
                let response = await this.$api.get(clients.getClient(this.client_id));
                if (response && response.data && response.data.length) {
                    //Leave it like this, unless you intend to do this.client = response.data?.[0] and then emit the data back to parent. right now this is read only.
                    //    this.client.id = response.data?.[0]?.id;
                    //    this.client.firstName = response.data?.[0]?.first_name;
                    //    this.client.lastName = response.data?.[0]?.last_name;
                    this.client = response.data?.[0];
                }
                this.$emit('getClient', this.client);
            },
            async getInsurances() {
                let response = await this.$api.get(clients.getInsurances(this.client_id));
                if (response && response.data && response.data.length) {
                    this.client.insurances = response.data;
                }
                this.insurance = response.data;
            },
            async gotoclient() {
                this.$router.push({ name: 'ClientOverview', params: { record_id: this.client_id.toString() } });
            },
            async gotoclientdocs() {
                this.$router.push({ name: 'ClientDocuments', params: { client_id: this.client_id.toString() } });
            },
            async portal2sign() {
                const update_record_data = {
                    client_id: parseInt(this.client_id),
                    page_id: this.pageMeta.id,
                }
                const res = await this.$api.put(`/form_data/portal2sign/${this.form_data_id}`, update_record_data)
                this.$toasted.success('Successfully Sent to Portal for Member Signature.');
            },
            async create_portal2sign() {
                if (!this.portalling) {
                    let component_name = this.pageMeta.component_name
                    this.$toasted.success('In progress : Creating and Sending to Portal for Member Signature.');
                    this.portalling = 1;
                    const update_record_data = {
                        user_id: parseInt(this.$store.state.user.id),
                        client_id: parseInt(this.client_id),
                        page_id: this.pageMeta.id,
                        component_name: component_name,
                        page_url: this.pageMeta.page,
                    }
                    const res = await this.$api.put(`/form_data/create-portal2sign/${this.pageMeta.id}`, update_record_data);
                    if (res && res.data && res.data.portal_rec_id) {
                        if (this.pageMeta.component_name == component_name ) {
                            await this.$router.push({ name: component_name, params: { record_id: res.data.portal_rec_id } })
                        }
                    }
                    this.portalling = 0;
                    this.$toasted.success('Successfully Sent ' + component_name + ' to Portal for Client Signature.');
                } else {
                    this.$toasted.error('In progress. Please wait and try again');
                }
            },
        },
        async created() {
            await this.getClient();
            this.getInsurances();
        }
    };
</script>
