<template>
    <div class="do-not-print-me flex justify-left">
        <button type="button" @click="sign()" v-show="showSignButton && (currentlySigning === false)" :disabled="disabled">
            <slot>
                <span class="material-icons-outlined font-16">edit</span> &nbsp;Sign
            </slot>
        </button>

        <button type="button" v-show="currentlySigning">
                        <Loading
                            :outerHeight="'19px'"
                            :outerWidth="'31.97px'"
                            :height="'19px'"
                            :width="'19px'"
                            :color="'white transparent transparent transparent'"
                            :borderWidth="'2px'"
                        />
        </button>
        <CreateSignaturePopup
            :open="showSignatureModal"
            @close="attemptToSignOnSignatureCreated"
            v-if="showSignatureModal"
        />
    </div>
</template>

<script>
    import { signatures, form_data, notes } from '@/util/apiRequests';
    import { createRawBuffer } from '@/util/pdf';
    import dayjs from '@/util/dayjs';
    import CreateSignaturePopup from '@/components/general/modals/CreateSignaturePopup';
    import Loading from '@/components/general/loading/loading';
    import { dryalex } from '@/mixins/dryalex';

    export default {
        components: { Loading, CreateSignaturePopup },
        props: {
            signatures_info: {
                default: []
            },
            signature_ids: {
                default: []
            },
            record_id: {
                default: null
            },
            fileName: {},
            //Please pass the save event here
            //The signing is a different endpoint, this helps keep the button generic.
            save: {},
            original_rec: {},
            rec: {},
            mustValidate: {
                type: Boolean,
                default: false
            },
            isValid: {
                type: Boolean,
                default: false
            },
          disabled: {
            default: false
          },
        },
        data() {
            return {
                showSignatureModal: false,
                mySignature: null,
                loading: true,
                currentlySigning: false,
            };
        },
        methods: {
            //this runs after new sig modal close event occurs
            async attemptToSignOnSignatureCreated(data) {
                this.showSignatureModal = false;
                //signature id
                if (data.signId) {
                    this.mySignature = await this.signatureToAdd();
                    await this.sign();
                }
            },
            async signatureToAdd() {
                const res = await this.$api.get(signatures.getSignature(this.whoIsSigning?.value?.id), {
                    params: {
                        t: this.whoIsSigning?.type
                    }
                });
                return res.data;
            },
            async sign(e) {
                try {
                    this.currentlySigning = true;
                    let form_data_has_changed = JSON.stringify(this.original_rec) != JSON.stringify(this.rec);
                    if (form_data_has_changed) {
                        if (this.$store.state.current_client_id && this.record_id && this.pageMeta.component_name != 'ProgressNote') {
                            if (this.mustValidate) {
                                this.$emit("commenceValidate");
                                if (!this.isValid) {
                                    this.currentlySigning = false;
                                    return;
                                }
                            }
                            let xthis1 = {
                                user_id: this.$store.state.user.id,
                                page_id: this.pageMeta.id,
                                client_id: this.$store.state.current_client_id,
                                this_record_id: this.record_id,
                                rec: this.$clone_obj(this.rec),
                                original_rec: this.$clone_obj(this.original_rec),
                                pageMeta: this.$clone_obj(this.pageMeta),
                                $api: this.$api,
                                signatures_needed: [],
                            }
                            let xthis2 = await dryalex.form_data_update_record(xthis1, e, 1);
                        } else {
                            alert('CANNOT SAVE THIS CHANGED FORM DATA')
                        }
                    }

                        // await save form data
                        // show modal signing...
                        // hit /form_data/signit api w/ user_id, and form_id
                        // NO pdf stuff
                        // on success sign, emit to FormSignature the new signature_ids

                        let signature = this.mySignature;
                        //no signature
                        if (!signature?.id) {
                            this.showSignatureModal = true;
                            //this.currentlySigning = false;
                            return;
                        }
                        //this.$emit('showModal', true);
                        this.$emit('showModal', true);

                        // try {
                        //     if (!this.save) {
                        //         return;
                        //     }
                        //     await this.save();
                        // } catch (error) {
                        //     //incase you pass nonsense to the save function
                        //     return;
                        // }

                        //We have existing signatures
                        if (this.signature_ids.length > 0) {
                            //Signature not found in already existing array of signatures
                            if (
                                this.signatures_info.some(
                                    (s) => s.sig_type == signature.sig_type && s.id == signature.id
                                ) == false
                            ) {
                                //Let's add the new signature
                            }
                            //Signature already exists
                            else {
                                this.$emit('showModal', false);
                                this.currentlySigning = false;
                                return;
                            }
                        }
                        //we have no existing signatures, let's add the first one
                        await this.$nextTick(async () => {
                            let signedAt = dayjs();
                            let i_am_a_progress_note = 0 // @todo determine if pn
                            if (this.pageMeta.id == 60) {
                                i_am_a_progress_note = 1
                            }
                            let sig_success = null
                            if (i_am_a_progress_note) {
                                sig_success = await this.$api.put(
                                    notes.signit(this.record_id ? this.record_id : this.$route.query?.id),
                                    {
                                        user_id: this.$store.state.user.id,
                                        page_id: this.pageMeta.id
                                        // template:this.pageMeta.page
                                    }
                                );
                            } else {
                                sig_success = await this.$api.put(
                                    form_data.signit(this.record_id ? this.record_id : this.$route.query?.id),
                                    {
                                        user_id: this.$store.state.user.id,
                                        page_id: this.pageMeta.id
                                        // template:this.pageMeta.page
                                    }
                                );
                            }
                            if (sig_success && sig_success.data.code) {
                                this.$emit('resetView', {
                                    id: this.whoIsSigning.value?.id,
                                    signatureId: signature.id,
                                    type: this.whoIsSigning.type,
                                    signedAt: signedAt
                                });
                            } else {
                                this.$emit('fail', {
                                    result: sig_success
                                });
                            }
                            this.currentlySigning = false;

                        }, 500);
                }catch(err){
                    this.$cl(err);
                    this.currentlySigning = false;
                    this.$emit('showModal', false);
                }
            }
        },
        computed: {
            //User or Client signing
            whoIsSigning() {
                if (this.$store.state.user) {
                    return { type: 'user', value: this.$store.state.user };
                } else {
                    return { type: 'client', value: this.$store.state.client };
                }
            },
            showSignButton() {
                if (this.loading == false) {
                    if (this.record_id == 0 || this.record_id == 'new') {
                        return false;
                    }
                    if (
                        this.signatures_info?.some(
                            (s) => s?.sig_type == this.mySignature?.sig_type && s?.id == this.mySignature?.id
                        )
                    ) {
                        return false;
                    }
                    return true;
                }
                return false;
            }
        },
        async mounted() {
            this.mySignature = await this.signatureToAdd();
            this.loading = false;
        }
    };
</script>

<style></style>
