<template>
    <div>
        <div v-if='showCompanyName'>
            <b>{{ company.company_name }}</b>
        </div>
        <div>
            <div v-if='showAddress'>{{ company.address }}</div>
            <div v-if='showExtraAddressDetails'>{{ company.city }}, {{ company.state }} {{ company.zip }}</div>
        </div>
        <div v-if='showPhone'>{{ company.phone }}</div>
    </div>
</template>

<script>
    import { companies } from '@/util/apiRequests';

    export default {
        data() {
            return {
                loading: 1,
                company: {},
            };
        },
        props: {
            showCompanyName: {
                type: Boolean,
                default: true,
            },
            showAddress: {
                type: Boolean,
                default: true,
            },
            showExtraAddressDetails: {
                type: Boolean,
                default: true,
            },
            showPhone: {
                type: Boolean,
                default: true,
            },

        },
        methods: {
            async getCompany() {
                let item = 'companies';
                try {
                    let result = await this.$api.get(companies.getCompanyInfo());
                    this.company = result.data[0];
                    this.$emit('getCompany', this.company);
                } catch (err) {
                    console.log(err);

                    //this.$toasted.error(`Could not get ${item}`);
                }
            },
        },
        created() {
            this.getCompany();
        },
    };
</script>

<style></style>
