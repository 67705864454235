<template>
    <div class="form-signature">
        <div v-if="signatures.length > 0">
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <div v-for="(signature, signatureIndex) in signature_ids" :key="signature.id + '-' + signatureIndex">
                <p v-if="show_witness && signature.id !== client_id" class="bold">Witness:</p>
                <p v-if="show_witness && signature.id === client_id" class="bold">Signature:</p>
                <img
                    v-if="file(signature)"
                    :src="`data:image/png;base64, ${file(signature)}`"
                    style="height: 100px; width: 250px"
                />
                <span v-else>Signed by</span>
                <div>{{ type(signature) }}</div>
                <div>
                    <span v-html='name(signature)'></span>
                    <!-- {{role(signature)}} -->
                    <template v-if="show_timestamp"><div>{{ time(signature) }} </div></template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { signatures } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';

    export default {
        name: 'FormSignature',
        props: {
            user_id: {
                type: Number,
                default: 0
            },
            client_id: {
                type: Number,
                default: 0
            },
            signature_ids: {},
            show_witness: {
                type: Boolean,
                default: false
            },
            show_timestamp : {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                loading: 1,
                signatures: [],
                initialLoad: false
            };
        },
        methods: {
            name(signature) {
                const { signatureId } = signature;
                const signatureObj = this.signatures.find((s) => s.id === signatureId);
                let name = `${signatureObj.first_name ?? ''} ${signatureObj.last_name ?? ''}`.trim();
                if (signatureObj.guardian_id) { // } && signatureObj.relationship_to_client) {
                    // name = '<span class="guardian-sig"><div>Parent / Guardian :</div><div>' + name + ', ' + signatureObj.relationship_to_client + '</div></span>'
                    name = '<span class="guardian-sig"><div>Parent / Guardian :</div><div>' + name + '</div></span>'
                }
                return name
            },
            time(signature) {
                let t = signature.signedAt || signature.signedAtUTCOnServer;
                if (typeof t !== 'undefined') {
                    return dayjs(t).format('MM/DD/YY h:mma');
                }
                return '';
            },
            file(signature) {
                const { signatureId } = signature;
                return this.signatures.find((s) => s.id === signatureId)?.file.Body;
            },
            type(signature) {
                const { signatureId } = signature;
                return this.signatures.find((s) => s.id === signatureId)?.type;
            },
            async getSignatures() {
                if (this.signature_ids) {
                    try {
                        //This will retrieve the signatures, with the s3 data I need
                        const res = await Promise.all(
                            this.signature_ids.map((element) =>
                                this.$api.get(signatures.getSignature(element.id), { params: { t: element.type } })
                            )
                        );

                        this.signatures = res.map((element) => {
                            let data = element.data;
                            return data;
                        });
                        this.$emit('getSignatures', this.signatures);
                        this.initialLoad = true;
                    } catch (error) {
                        // //this.$toasted.error('no signatures found');
                    }
                }
                {
                    // //this.$toasted.error('no signatures founds.');
                }
            }
        },
        async created() {
            this.getSignatures();
        },
        watch: {
            signature_ids: {
                deep: true,
                immediate: true,
                handler() {
                    // newVal, oldVal
                    if (this.initialLoad == true) {
                        this.getSignatures();
                    }
                }
            }
        }
    };
</script>